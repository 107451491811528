.section {
    padding-top: 80px;
    padding-bottom: 80px;

    @media (max-width: 992px) {
        padding-top: 40px;
        padding-bottom: 0;
    }

    hr.section-separator {
        border: none;
        height: 3px;
        background: color('primary');
        color: color('primary');
        width: 100px;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;

        &:before {
            content: '';
            height: 1px;
            color: color('primary');
            background: color('primary');
            width: 50px;
            display: block;
            position: absolute;
            left: -50px;
            top: 1px;
        }

        &:after {
            content: '';
            height: 1px;
            color: color('primary');
            background: color('primary');
            width: 50px;
            display: block;
            position: absolute;
            right: -50px;
            top: 1px;
        }
    }

    .section-title {
        font-family: font(main);
        font-size: 46px;
        font-weight: 600;
        color: color('text', 'light');
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 992px) {
            font-size: 20px;
        }

        a {
            text-decoration: none;
            // border-bottom: 2px solid transparent;
        }

        &:hover {
            a {
                // border-bottom: 2px solid #FFF;
            }
        }
    }

    .section-subtitle {
        font-family: font(main);
        font-weight: 300;
        font-size: 28px;
        color: color('text', 'dark');
        text-align: center;
        font-style: italic;
    }

    .section-text {
        font-family: font(main);
        font-weight: 400;
        font-size: 22px;
        color: color('text', 'dark');
        margin-top: 20px;
        margin-bottom: 20px;
        @include page-text();

        @media (max-width: 992px) {
            p {
                font-size: 16px;
            }
        }
    }

    .section-btn {
        margin: 0 auto;
        color: color('white') !important;
    }

    &.scroll-section {
        padding: 0;

        .section-title {
            margin: 0 auto 60px;

            @media (max-width: 992px) {
                margin-bottom: 22px;
            }
        }

        .section-text {
            margin: 0 auto;
        }

        &.full-width {
            .section-title,
            .section-text {
                max-width: 75%;
            }
        }

        @media (max-width: 746px) {
            .content-column {
                text-align: center !important;

                .section-title,
                .section-text {
                    max-width: 90%;
                    text-align: center !important;
                    display: inline-block;
                }
            }
        }

        h2,
        ul,
        .site-logo,
        .section-text,
        .form-description,
        .homepage-form-wrapper {
            opacity: 0;
            transition: all ease-in 1s;
        }

        &.active {
            h2,
            ul,
            .site-logo,
            .section-text,
            .form-description,
            .homepage-form-wrapper {
                opacity: 1;
            }
        }

        @media (max-width: 992px) {
            .form-description {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
    }
}

a {
    &.no-underline {
        text-decoration: none;
    }
}