/* FONT IMPORTS */
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');

/* FONTS */
$fonts: (
    Montserrat: ('Barlow Condensed', sans-serif),
    OpenSans: ('Open Sans', sans-serif),
    main: ('Barlow Condensed', sans-serif),
    sub: ('Open Sans', sans-serif),
);

@function font($key) {
    @if map-has-key($fonts, $key) {
        @return map-get($fonts, $key);
    }

    @warn "Unknown `#{$key}` in $fonts.";
    @return null;
}